.FormContainerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 400px;
  height: 400px;
  padding: 30px;
  background-color: #fff;
  border-radius: 25px;
}

.TextField {
  width: 100% !important;
}

.FormButton {
  margin-top: 25px !important;
  color: white !important;
  background-color: #3377cf !important;
  width: 100% !important;
  height: 50px;
  border-radius: 10px !important;
}

.FormTitle {
  height: 50px;
  font-size: 20px;
  text-align: center;
}

.Form input {
  height: 55px;
  padding: 0px 14px;
}

.Form .SelectField {
  margin-top: 20px;
}
