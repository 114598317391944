.edit-agent-container {
    display: flex;
    align-items: center;
    height: calc(100vh - 75px);
    justify-content: center;
}

.edit-agent-container .agent-card-item {
    display: flex;
    flex-direction: column;
}

.edit-agent-container .agent-card-item .label{
    display: inline-block;
}

.edit-agent-container .agent-card-item .agent-info-item{
    margin-bottom: 20px;
}

.edit-agent-container .agent-card-item .submit-button{
    margin-top: auto;
    width: 150px;
    align-self: center;
}
.edit-agent-container .agent-card-item {
    min-width: 400px;
    min-height: 500px;
    padding: 20px;
    margin: 10px;
}

.edit-agent-container .password-mismatch {
    margin-top: 5px;
    color: red;
    font-size: 12px;
}