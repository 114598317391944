.edit-agent-container {
    align-items: unset;
}

.pictures-container {
    margin: 10px;
    cursor: pointer;
}

.location-edit .label {
    margin-right: 20px;
}

.location-edit ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

/*.location-edit-carousel .slider-wrapper {*/
/*max-height: 70vh;*/
/*}*/

.location-edit li {
    height: 40vh;
    flex-grow: 1;
}

.location-edit li:last-child {
    flex-grow: 10;
}

.location-edit img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
}

@media (max-aspect-ratio: 1/1) {
    .location-edit li {
        height: 15vh;
    }
}

@media (max-height: 480px) {
    .location-edit li {
        height: 40vh;
    }
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
    .location-edit ul {
        flex-direction: row;
    }

    .location-edit li {
        height: auto;
        width: 100%;
    }

    .location-edit img {
        width: 100%;
        max-height: 35vh;
        min-width: 0;
    }
}