header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 10px 20px;
  box-shadow: 0px 2px 5px 0px rgba(182, 189, 189, 1);
  position: relative;
  color: white;
  background-color: #939393;
}

header .app-title {
  font-size: 24px;
  font-weight: 700;
}

header nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

header nav ul li {
  display: inline-block;
  margin: 10px;
}

header nav ul li a {
  color: white;
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  text-transform: capitalize;
  padding-top: 0;
  text-decoration: none;
}

header nav ul li a:hover {
  /* color: #0099ff; */
  color: black;
}

.profile-menu-dropdown {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
  right: 0;
  padding: 8px 12px 10px;
  position: absolute;
  text-align: left;
  top: 100%;
  width: 125px;
  z-index: 220;
  visibility: hidden;
  opacity: 0;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
}
.profile-menu-dropdown ul {
  margin: 0;
  cursor: pointer;
  list-style: none;
}
.profile-menu-dropdown a,
button {
  background: none;
  border: none;
  line-height: 27px;
  font-size: 13px;
  color: #333;
  display: block;
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
  padding-top: 0;
  text-decoration: none;
}

.profile-menu-dropdown :hover {
  background-color: #f6f8fb;
}
.profile-menu-dropdown a:hover,
button:hover {
  /* color: #0099ff; */
}

.profile-info {
  height: 75px;
  line-height: 75px;
}

.profile-info:hover {
  color: black;
  cursor: pointer;
}

.profile-info:hover .profile-menu-dropdown {
  visibility: visible;
  opacity: 1;
  transform: rotateX(0deg);
}

header .active {
  color: black;
}

.MuiSvgIcon-root {
  margin-right: 5px;
  vertical-align: -5px;
}
