.filters-container {
    display: flex;
    padding: 20px 0;
}

.filters-container .filter-item {
    min-width: 120px;
    margin-right: 20px;
}

.filters-container .filter-item >*{
    min-width: 100%;
}