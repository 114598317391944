@import "./components/header/header.component.css";
@import "./stylesheets/fonts.css";
@import "components/filters/filters.component.css";
@import "./views/edit-agent/edit-agent.component.css";

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #f7f7f7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.popup-overlay  {
  max-height: unset !important;
  overflow-y: auto !important;
}
