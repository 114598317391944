.UserListContainer {
  margin: 40px;
}

.FilterItem {
  width: 100%;
}

.NavigationContainer {
  display: flex;
  justify-content: space-between;
}
